<template>
  <div class="download">
    <div class="download-search">
      <a-form-model ref="searchFormRef" layout="inline" :model="searchForm">
        <a-form-model-item label="附件类型" prop="fileType">
          <a-select
            v-model="searchForm.fileType"
            placeholder="附件类型"
            style="width: 200px"
            allowClear
          >
            <a-select-option v-for="item in fileTypeList" :value="item.entryValue">{{
              item.entryKey
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="onClickSearch"> 查询 </a-button>
          <a-button @click="onClickAdd" style="margin-left: 10px"> 新增 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="download-table" style="margin-top: 20px">
      <a-table
        class="table"
        rowKey="id"
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :loading="tableLoading"
        @change="handleTableChange"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="fileType" slot-scope="text, record">
          {{ record.fileType ? getDictLabel(fileTypeList, record.fileType) : "" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="onClickEdit(record)">修改 </a-button>
          <a-button type="link" @click="onClickDelete(record)">删除 </a-button>
        </template>
      </a-table>
    </div>
    <div class="download-action">
      <a-modal
        v-model="action.show"
        :title="action.title"
        :maskClosable="false"
        width="800px"
        destroyOnClose
      >
        <a-spin :spinning="formLoading" tip="正在上传文件中,请稍等...">
          <a-form-model
            :model="action.form"
            :label-col="action.labelCol"
            :wrapper-col="action.wrapperCol"
            :rules="action.rules"
            ref="ruleForm"
          >
            <a-form-model-item label="名称" prop="name">
              <a-input
                v-model="action.form.name"
                placeholder="请输入名称"
                :maxlength="20"
              />
            </a-form-model-item>
            <a-form-model-item label="版本号" prop="version">
              <a-input v-model="action.form.version" placeholder="请输入版本号" />
            </a-form-model-item>
            <a-form-model-item label="地区名称" prop="areaName">
              <a-input v-model="action.form.areaName" placeholder="请输入地区名称" />
            </a-form-model-item>
            <a-form-model-item label="上传" prop="downloadUrl">
              <div class="upload">
                <div class="upload-btn">
                  <a-upload :show-upload-list="false" :customRequest="uploadFile">
                    <a-button type="primary">上传</a-button>
                  </a-upload>
                </div>
                <div
                  class="upload-url"
                  v-if="action.form.downloadUrl !== ''"
                  style="font-size: 12px"
                >
                  {{ action.form.downloadUrl }}
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="附件类型" prop="fileType">
              <a-select
                v-model="action.form.fileType"
                placeholder="请选择附件类型"
                allowClear
              >
                <a-select-option v-for="item in fileTypeList" :value="item.entryValue">{{
                  item.entryKey
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </a-spin>
        <template #footer>
          <a-button
            key="submit"
            type="primary"
            :loading="action.loading"
            @click="onClickSave"
            >确定</a-button
          >
          <a-button danger @click="onClickClose">取消</a-button>
        </template>
      </a-modal>
    </div>

    <a-modal v-model="deleted.show" title="提示" @ok="onConfirmDelete()">
      <div>确定删除本条数据吗？</div>
    </a-modal>
  </div>
</template>
<script>
import Vue from "vue";
import { dictListCommon, getDictLabel, dateStringToTimestamp } from "@/utils/index";
import {
  downloadFileList,
  actionDownloadFile,
  deleteDownloadFile,
  downloadFileDetail,
} from "@/api/download";
import AliOss from "@/utils/alioss";
export default Vue.extend({
  name: "download",
  data() {
    return {
      searchForm: {
        fileType: "",
      },
      fileTypeList: [],
      columns: [
        {
          title: "序号",
          align: "left",
          dataIndex: "number",
          width: "80px",
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "名称",
          dataIndex: "name",
          width: "120px",
        },
        {
          title: "版本号",
          dataIndex: "version",
          width: "120px",
        },
        {
          title: "地区名称",
          dataIndex: "areaName",
          width: "120px",
        },
        {
          title: "下载地址",
          dataIndex: "downloadUrl",
          width: "250px",
        },
        {
          title: "文件类型",
          dataIndex: "fileType",
          scopedSlots: { customRender: "fileType" },
          width: "120px",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
          width: "100px",
        },
      ],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        total: 0,
        showQuickJumper: true,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
      tableLoading: false,
      action: {
        show: false,
        title: "新增",
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
        loading: false,
        form: {
          sequenceNbr: "",
          name: "",
          version: "",
          areaName: "河北省",
          downloadUrl: "",
          fileType: undefined,
        },
        rules: {
          name: [
            { required: true, message: "请输入名称" },
            { max: 20, message: "最多输入20个字符" },
          ],
          version: [{ required: true, message: "请输入版本号" }],
          areaName: [{ required: true, message: "请输入地区名称" }],
          downloadUrl: [{ required: true, message: "请上传文件" }],
          fileType: [{ required: true, message: "请选择文件类型" }],
        },
      },
      deleted: {
        show: false,
        id: "",
      },
      formLoading: false,
    };
  },
  methods: {
    getDictLabel,
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    onClickSearch() {
      this.pagination.current = 1;
      this.getList();
    },
    getList() {
      let reqData = {
        ...this.searchForm,
        limit: this.pagination.pageSize,
        page: this.pagination.current,
      };
      downloadFileList(reqData).then((res) => {
        if (res.status === 200) {
          this.tableData = res.result.list;
          this.pagination.total = res.result.total;
        } else {
          this.tableData = [];
          this.pagination.total = 0;
        }
      });
    },
    onClickEdit(record) {
      this.action.show = true;
      this.action.title = "修改";
      downloadFileDetail(record.sequenceNbr).then((res) => {
        if (res.status === 200) {
          this.action.form = {
            sequenceNbr: res.result.sequenceNbr,
            name: res.result.name,
            version: res.result.version,
            areaName: res.result.areaName,
            downloadUrl: res.result.downloadUrl,
            fileType: res.result.fileType,
          };
        }
      });
    },
    onClickAdd() {
      this.action.show = true;
      this.action.title = "新增";
      this.action.form = {
        sequenceNbr: "",
        name: "",
        version: "",
        areaName: "河北省",
        downloadUrl: "",
        fileType: undefined,
      };
      this.$refs.ruleForm.resetFields();
    },
    onClickSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.action.loading = true;
          actionDownloadFile(this.action.form)
            .then(() => {
              this.action.loading = false;
              this.action.show = false;
              this.getList();
            })
            .catch(() => {
              this.action.loading = false;
            })
            .finally(() => {
              this.action.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    onClickClose() {
      this.action.show = false;
    },
    async uploadFile(params) {
      const file = params.file;
      let url = "";
      if (!file) return false;
      this.formLoading = true;
      url = await new AliOss().upload(file);
      this.formLoading = false;
      this.$refs.ruleForm.validateField("fileType");
      this.action.form.downloadUrl = url;
    },
    onClickDelete(record) {
      this.deleted.show = true;
      this.deleted.id = record.sequenceNbr;
    },
    onConfirmDelete() {
      deleteDownloadFile([this.deleted.id])
        .then((res) => {
          if (res.status === 200) {
            this.deleted.show = false;
            this.deleted.id = "";
            this.$message.success("删除成功");
            this.getList();
          }
        })
        .finally(() => {
          this.deleted.show = false;
          this.deleted.id = "";
        });
    },
  },
  mounted() {
    dictListCommon("download_type").then((res) => {
      this.fileTypeList = res;
    });
    this.getList();
  },
});
</script>
<style lang="less" scoped></style>
