import http from '@/utils/http';

export function downloadFileList(formdata: any): any {
  return http.get(`/portal-resources/1/downloadFiles/queryByPage`, formdata);
}

export function actionDownloadFile(reqData: any): any {
  if ((reqData.sequenceNbr ?? '') === '') {
    return http.post(`/portal-resources/1/downloadFiles`, reqData);
  }
  else {
    return http.put(`/portal-resources/1/downloadFiles`, reqData);
  }
}

export function deleteDownloadFile(reqData: any): any {
  return http.delete(`/portal-resources/1/downloadFiles`, reqData);
}

export function downloadFileDetail(id: string): any {
  return http.get(`/portal-resources/1/downloadFiles/${id}`);
}